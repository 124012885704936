.mapboxgl-popup-content{
 box-shadow: 0 4px 60px rgba(0,0,0,.2);
}

.placeItemContainer{
  margin-top:15px;
}

.subPlaceItem{
  line-height:32px;
  border-top:1px solid #f0f0f0;
  border-bottom:1px solid #f0f0f0;
  margin-top:-1px;
  cursor:pointer;
  min-width: 140px;
}

.subPlaceItem:hover{
  background:#f9f9f9;
}


.journeyBg {
  padding:10px 20px;
}

.waypointTitle {
  font-size:15px;
  font-weight:700;
}

.waypointDescription {
  margin-top:10px;
  max-width:280px;
  text-align: left;
}

.waypointNavigation {
  margin-top:30px;
}

.buttonPlaceholderLeft{
  float:left;
  line-height: 32px;
  width:34px;
  visibility: hidden;
}

.buttonPlaceholderRight{
  float:right;
  line-height: 32px;
  width:34px;
  visibility: hidden;
}

.prevButton{
  float:left;
  line-height: 32px;
  width:34px;
  border:1px solid #999;
  color:#999;
  border-radius: 50%;
  cursor: pointer;
}

.waypointIndex {
  font-size:13px;
  color:#999;
  display:inline;
  line-height: 32px;
}

.nextButton{
  float:right;
  line-height: 32px;
  width:34px;
  border:1px solid #999;
  color:#999;
  border-radius: 50%;
  cursor: pointer;
}

.prevButton:hover,
.nextButton:hover {
  border:1px solid #1c6ecc;
  color:#1c6ecc;
}