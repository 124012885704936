body {
  margin:0;
  padding:0;
  box-sizing: border-box;

  font-family:'Lato', 'Helvetica Neue', 'Arial', sans-serif;
  font-weight:300;

}

div {
  box-sizing: border-box;
}

h1 {
  margin-top:0;
  font-size:70px;
  font-weight:900;
}

input {
  font-family:'Lato', 'Helvetica Neue', 'Arial', sans-serif;
  line-height: 32px;
  width:100%;
  font-size:14px;
  padding-left:6px;
  box-sizing: border-box;
  /*-webkit-appearance: none;*/
}

label {
  font-weight:700;
  font-size:12px;
  margin-bottom:6px;
  line-height:21px
}

.serifFont {
  font-family: 'Merriweather', serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 10s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #b9b9b9;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #b9b9b9;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #b9b9b9;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    #b9b9b9;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color:    #b9b9b9;
}

span:focus {
  outline:none;
}

.multiEmailInputText:focus {
  outline: none;
}
