body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  margin:0;
  padding:0;
  box-sizing: border-box;

  font-family:'Lato', 'Helvetica Neue', 'Arial', sans-serif;
  font-weight:300;

}

div {
  box-sizing: border-box;
}

h1 {
  margin-top:0;
  font-size:70px;
  font-weight:900;
}

input {
  font-family:'Lato', 'Helvetica Neue', 'Arial', sans-serif;
  line-height: 32px;
  width:100%;
  font-size:14px;
  padding-left:6px;
  box-sizing: border-box;
  /*-webkit-appearance: none;*/
}

label {
  font-weight:700;
  font-size:12px;
  margin-bottom:6px;
  line-height:21px
}

.serifFont {
  font-family: 'Merriweather', serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 10s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #b9b9b9;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #b9b9b9;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #b9b9b9;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    #b9b9b9;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color:    #b9b9b9;
}

span:focus {
  outline:none;
}

.multiEmailInputText:focus {
  outline: none;
}

.mapboxgl-popup-content{
 box-shadow: 0 4px 60px rgba(0,0,0,.2);
}

.placeItemContainer{
  margin-top:15px;
}

.subPlaceItem{
  line-height:32px;
  border-top:1px solid #f0f0f0;
  border-bottom:1px solid #f0f0f0;
  margin-top:-1px;
  cursor:pointer;
  min-width: 140px;
}

.subPlaceItem:hover{
  background:#f9f9f9;
}


.journeyBg {
  padding:10px 20px;
}

.waypointTitle {
  font-size:15px;
  font-weight:700;
}

.waypointDescription {
  margin-top:10px;
  max-width:280px;
  text-align: left;
}

.waypointNavigation {
  margin-top:30px;
}

.buttonPlaceholderLeft{
  float:left;
  line-height: 32px;
  width:34px;
  visibility: hidden;
}

.buttonPlaceholderRight{
  float:right;
  line-height: 32px;
  width:34px;
  visibility: hidden;
}

.prevButton{
  float:left;
  line-height: 32px;
  width:34px;
  border:1px solid #999;
  color:#999;
  border-radius: 50%;
  cursor: pointer;
}

.waypointIndex {
  font-size:13px;
  color:#999;
  display:inline;
  line-height: 32px;
}

.nextButton{
  float:right;
  line-height: 32px;
  width:34px;
  border:1px solid #999;
  color:#999;
  border-radius: 50%;
  cursor: pointer;
}

.prevButton:hover,
.nextButton:hover {
  border:1px solid #1c6ecc;
  color:#1c6ecc;
}
.searchContainer {
  background:transparent;
  transition: background 0.3s;
}

#searchContainer.active{
  background:#DAC46D;
  width:100%;
  transition: background 0.3s;
}

#icnSearch{
  position:absolute;
  top:0px;
  right:0px;
}

#icnSearch.active{
  right:100%;
  transform:translateX(100%);
  transition: .4s;
}

#searchCircle.active{
  stroke: #fff;
  transition: .1s;
}

#searchRectangle.active{
  fill: #fff;
  transition: .1s;
}

#icnClose {
  opacity:0;
}

#icnClose.active {
  opacity: 1;
  transition: .3s;
}

#searchInput{
  /*display:none;*/
  opacity:0;
  margin-left:100%;
  width:1px;
}

#searchInput.active{
  /*display:block;*/
  opacity:1;
  width:76%;
  margin-left:12px;
  transition: opacity .5s .3s;
}

#searchInput::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    rgba(255,255,255,.5);
}
#searchInput:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #fff;
  opacity:  .5;
}
#searchInput::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #fff;
  opacity:  .5;
}
#searchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    rgba(255,255,255,.5)
}
#searchInput::-ms-input-placeholder { /* Microsoft Edge */
  color:    rgba(255,255,255,.5)
}
#shareDialog{
  margin-top:100vh;
  opacity:1;
  transition: all 0.4s ease-in-out;
}

#shareDialog.active{
  opacity: 1;
  margin-top:0vh;
  transition: all 0.3s ease-out;
}
