.searchContainer {
  background:transparent;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

#searchContainer.active{
  background:#DAC46D;
  width:100%;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
}

#icnSearch{
  position:absolute;
  top:0px;
  right:0px;
}

#icnSearch.active{
  right:100%;
  transform:translateX(100%);
  -webkit-transition: right 0.4s;
  -moz-transition: right 0.4s;
  -o-transition: right 0.4s;
  transition: .4s;
}

#searchCircle.active{
  stroke: #fff;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -o-transition: 0.1s;
  transition: .1s;
}

#searchRectangle.active{
  fill: #fff;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -o-transition: 0.1s;
  transition: .1s;
}

#icnClose {
  opacity:0;
}

#icnClose.active {
  opacity: 1;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: .3s;
}

#searchInput{
  /*display:none;*/
  opacity:0;
  margin-left:100%;
  width:1px;
}

#searchInput.active{
  /*display:block;*/
  opacity:1;
  width:76%;
  margin-left:12px;

  -webkit-transition: opacity .5s .5s;
  -moz-transition: opacity .5s .5s;
  -o-transition: opacity .5s .5s;
  transition: opacity .5s .3s;
}

#searchInput::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    rgba(255,255,255,.5);
}
#searchInput:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #fff;
  opacity:  .5;
}
#searchInput::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #fff;
  opacity:  .5;
}
#searchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    rgba(255,255,255,.5)
}
#searchInput::-ms-input-placeholder { /* Microsoft Edge */
  color:    rgba(255,255,255,.5)
}